textarea:focus, input:focus{
  outline: none;
}

@keyframes hoverBlack {
  from {
      color:#d0a340;
  }
  to {
      color: black;
  }
}

@keyframes hoverOrange {
  from {
      color: black;
  }
  to {
      color:#d0a340;
  }
}

@keyframes fadeInLoad {
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}