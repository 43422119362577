.atlas-page{
    width: 100%;
    height: 100%;
}

.atlas-section{
    width: 100%;
}

.atlas-title{
    margin: auto;
    width: 90%;
    height: 60px;
    border-bottom: 1px solid black;
    font-size: 32px;
    font-weight: bold;
}

.atlas-lugares-section{
    background-color: #f2f3f2;
    width: 100%;
    padding: 80px 0px;
}

.atlas-entidades-section{
    background-color: white;
    width: 100%;
}

@media only screen and (max-width: 700px) {
    .atlas-title {
        width: calc(100vw - 30px);
        height: auto;
        min-height: 50px;
        padding-bottom: 10px;
        border-bottom: 1px solid black;
        font-size: 30px;
    }
}

/* ASALVADOR */

.atlas-page .marker.markerasalvador {
    height: 57px;
    border-radius: 0;
    background-size: contain;
}

.nivel-0, .nivel-1, .nivel-2, .nivel-3 {
    display: none;
}

.atlas-subpage-content.formulario-de-contacto {
    max-width: 90%;
    margin: 0 auto;
}

.atlas-subpage-content.parcerias-asalvador {
    max-width: 90%;
    margin: 0 auto
}

.citacao-autor {
    margin-top: -10px;
    font-size: 14px;
    line-height: 1.2em;
}

.coluna-logos {
    max-width: 512px;
}

.coluna-logos img {
    object-fit: contain;
}

.atribuido-item-content .atribuido-item-nivel-image {
    background-size: contain;
}

.nivel-acessibilidade-nivel-name {
    font-size: 20px;
    line-height: 1em;
    margin-top: 10px;
}