.filter-section{
    width: calc(100% - 200px);
    margin: auto;
    display: grid;
    padding-top: 60px;
    column-gap: 15px;
}

.filter-button{
    cursor: pointer;
    text-align: center;
    height: 60px;
    line-height: 60px;
    background: linear-gradient(to left, white 50%, #505050 50%) right;
    background-size: 210%;
    transition: .25s ease-out;
    font-weight: bold;
    font-size: 15px;
    display: flex;
}

/* .filter-button.darkMode{
    background: linear-gradient(to left, #f0f3f2 50%, #505050 50%) right;
    color: black;
    background-size: 210%;
} */

.filter-button.darkMode{
    background: linear-gradient(to left, #f0f3f2 50%, #d0a240 50%) right;
    color: black;
    background-size: 210%;
}

.filter-button.orangeHover{
    background: linear-gradient(to left, white 50%, #d0a240 50%) right;
    background-size: 210%;
}

.filter-button.darkMode.orangeHover{
    background: linear-gradient(to left, #f0f3f2 50%, #d0a240 50%) right;
    background-size: 210%;
}

.filter-button.active, .filter-button.active.orangeHover, .filter-button.active.darkMode.orangeHover{
    background: linear-gradient(to left, #505050 50%, #505050 50%) right;
    background-color: #505050;
    color: white;
}

.filter-button:hover{
    background-position: left;
}

.filter-button.orangeHover:hover, .filter-button.darkMode:hover{
    background-position: left;
    color: white;
}

.filter-button-text{
    margin: auto;
    font-size: 15px;
    line-height: 25px;
}

@media only screen and (max-width: 700px) {
    .filter-section {
        width: calc(100% - 30px);
        display: grid;
        padding-top: 20px;
        grid-template-columns: 50% 50% !important;
        column-gap: 0px !important;
    }

    .filter-button {
        width: calc(100% - 10px);
        margin: 5px;
    }

    .filter-button.darkMode, .filter-button.orangeHover,.filter-button.darkMode.orangeHover{
        background-size: 240%;
    }
}