.home-page, .home-content{
    width: 100%;
    height: 100%;
}

.home-projects-highlights-container{
    width: 100%;
    background-color: #f2f3f2;
}

.home-projects-highlights-wrapper{
    width: 90%;
    max-width: 1300px;
    display: grid;
    grid-template-columns: calc(100% - 400px) 400px;
    margin: auto;
}

.home-projects-wrapper{
    width: 85%;
    height: auto;
    padding-top: 70px;
    padding-bottom: 60px;
}

.home-projects-header{
    width: 100%;
    display: flex;
    margin: auto;
    height: 75px;
    border-bottom: 1px solid black;
}

.home-projects-header a{
    text-decoration: none;
}

.home-projects-title{
    font-size: 43px;
    font-weight: bold;
    flex: 1;
}

.home-projects-content{
    padding-top: 20px;
    margin: auto;
    width: 100%;
}

.highlights-wrapper{
    width: 100%;
    height: auto;
    margin: auto;
    margin-top: 150px;
}

.highlights-header{
    width: 100%;
    height: 90px;
    line-height: 90px;
    text-align: center;
    cursor: pointer;
    background-color: #d0a240;
    color: white;
}

.highlights-header a{
    text-decoration: none;
}

.highlights-title{
    font-size: 25px;
    font-weight: bold;
    flex: 1;
    letter-spacing: 1px;
}

.highlights-content{
    margin: auto;
    width: 100%;
}

@media only screen and (max-width: 1000px) {
    .home-projects-highlights-wrapper{
        width: 90%;
        display: grid;
        grid-template-columns: 1fr;
        margin: auto;
    }

    .home-projects-highlights-wrapper:first-child{
        grid-row: 2;
    }

    .home-projects-wrapper {
        width: 100%;
        padding: 40px 0px 0px 0px;
    }

    .home-projects-header {
        height: 65px;
    }

    .home-projects-title {
        font-size: 38px;
    }

    .highlights-wrapper {
        order: -1;
        margin-top: 50px;
    }

    .highlights-header {
        width: 285px;
        height: 50px;
        line-height: 50px;
        margin: auto;
    }

    .highlights-title {
        font-size: 14px;
        letter-spacing: 0px;
    }
}

@media only screen and (max-width: 700px) {
    .home-projects-highlights-wrapper{
        width: calc(100vw - 30px);
    }
}