.sentenceBanner-wrapper{
    width: 100%;
    overflow-x: hidden;
    height: 450px;
    background-color: #d0a240;
}

.sentence-section-wrapper{
    padding-top: 80px;
}

.sentence-section{
    font-size: 125px;
    letter-spacing: 3px;
    font-weight: bold;
    color: #e0c381;
    width: max-content;
    transition: 0.3s;
}

.sentence-section.slideLeft{
    /* -moz-animation: 8s slide-left;
    -webkit-animation: 8s slide-left;
    -o-animation: 8s slide-left;
    animation: 8s slide-left;
    animation-iteration-count: infinite; */
    transition: 1s;
    padding-left: 100%;
    animation: marquee 15s linear infinite;
}

.sentence-section.slideLeft.second{
    animation-delay: 7.5s;
}

@keyframes marquee {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(-100%, 0);
    }
}

/* 
@-webkit-keyframes slide-left { 
    from {
        margin-left: 100%;
        width: 100%;
    }
    to {
        margin-left: 0%;
        width: 100%;
    } 
}
@-moz-keyframes slide-left { 
    from {
        margin-left: 100%;
        width: 100%;
    }
    to {
        margin-left: 0%;
        width: 100%;
    } 
}
@-o-keyframes slide-left { 
    from {
        margin-left: 100%;
        width: 100%;
    }
    to {
        margin-left: 0%;
        width: 100%;
    } 
}
@keyframes slide-left { 
    from {
        margin-left: 100%;
        width: 100%;
    }
    to {
        margin-left: 0%;
        width: 100%;
    } 
} */

@media only screen and (max-width: 700px) {
    .sentenceBanner-wrapper {
        height: 250px;
        display: flex;
    }

    .sentence-section-wrapper {
        padding: 0px;
        margin: auto;
    }

    .sentence-section {
        font-size: 50px;
        letter-spacing: 1px;
        line-height: 90px;
    }
}