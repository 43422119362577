.icvm-page{
    width: 100%;
    height: 100%;
}

.icvm-content{
    width: 100%;
    height: 100%;
}
/* 
@property --num1 {
    syntax: "<integer>";
    initial-value: 0;
    inherits: false;
}

@property --num2 {
    syntax: "<integer>";
    initial-value: 0;
    inherits: false;
}

@property --num3 {
    syntax: "<integer>";
    initial-value: 0;
    inherits: false;
}

@property --num4 {
    syntax: "<integer>";
    initial-value: 0;
    inherits: false;
}

@keyframes counter1 {
    from {
        --num1: 0;
    }
    to {
        --num1: 10;
    }
}

@-webkit-keyframes counter1 {
    from {
        --num1: 0;
    }
    to {
        --num1: 10;
    }
}

@-moz-keyframes counter1 {
    from {
        --num1: 0;
    }
    to {
        --num1: 10;
    }
}

@keyframes counter2 {
    from {
        --num2: 0;
    }
    to {
        --num2: 50;
    }
}

@keyframes counter3 {
    from {
        --num3: 0;
    }
    to {
        --num3: 150;
    }
}

@keyframes counter4 {
    from {
        --num4: 0;
    }
    to {
        --num4: 500000;
    }
}

.numbers-icvm-1{
    animation: counter1 4.5s forwards;
    counter-set: num var(--num1);
    animation-delay: 0.5s;
    font-size: 0px;
    line-height: 0px;
}

.numbers-icvm-2{
    animation: counter2 4.5s forwards;
    counter-set: num var(--num2);
    animation-delay: 0.5s;
    font-size: 0px;
    line-height: 0px;
}

.numbers-icvm-3{
    animation: counter3 4.5s forwards;
    counter-set: num var(--num3);
    animation-delay: 0.5s;
    font-size: 0px;
    line-height: 0px;
}

.numbers-icvm-4{
    animation: counter4 4.5s forwards;
    counter-set: num var(--num4);
    animation-delay: 0.5s;
    font-size: 0px;
    line-height: 0px;
}

.numbers-icvm-4::before{
    content: "1";
    font-size: 37px;
}

.icvm-content .numbers-icvm h3 strong {
    font-size: 0px;
    line-height: 0px;
}

.numbers-icvm-1::after,
.numbers-icvm-2::after,
.numbers-icvm-3::after,
.numbers-icvm-4::after {
    font-size: 37px;
    content: counter(num);
}

.icvm-content .numbers-icvm p {
    font-size: 23px;
    line-height: 28px;
    font-weight: bold;
    color: #505050;
}
 */
@media only screen and (max-width: 1000px) {
    .icvm-content .numbers-icvm h3 strong::after, 
    .numbers-icvm-1::after,
    .numbers-icvm-2::after,
    .numbers-icvm-3::after,
    .numbers-icvm-4::after,
    .numbers-icvm-4::before {
        font-size: 25px;
    }

    .icvm-content .numbers-icvm p {
        font-size: 18px;
        line-height: 22px;
    }
}