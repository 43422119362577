.loader-wrapper{
    width: 100%;
    height: 350px;
    display: flex;
}

.loader-wrapper.fullHeight{
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    display: flex;
    position: fixed;
    top: 0px;
    z-index: 100;
}

.loader-wrapper.medium {
    height: calc(100vh - 100px);
}

.loader-wrapper.loadMore {
    height: 160px;
}

.loader-icon{
    width: 80px;
    height: 80px;
    background-image: url(../../assets/loader/ICVM_Loader_Transparente.gif);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin: auto;
    margin-top: 50px;
}

.loader-icon.fullHeight, .loader-icon.loadMore{
    margin: auto;
}

@media only screen and (max-width: 700px) {
    .loader-wrapper.fullHeight {
        width: 100vw;
        height: 100vh;
    }
}