.numbers-icvm-wrapper{
    display: grid;
    grid-template-columns: repeat(auto-fill, 300px);
    margin: auto;
    width: 90%;
    justify-content: center;
    height: auto;
    padding-bottom: 30px;
    max-width: 1200px;
}

.number-item-wrapper{
    height: 100%;
    width: 100%;
    text-align: center;
}

.number-item-image{
    width: 200px;
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin: auto;
}

.number-item-number{
    font-size: 33px;
    font-weight: bold;
    margin-top: 10px;
}

.number-item-text{
    font-size: 23px;
    margin-top: 20px;
    line-height: 28px;
    font-weight: bold;
    color: #505050;
}