.pageBanner-wrapper{
    width: 100%;
    min-height: 380px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
}

.pageBanner-text-section{
    width: calc(100% - 200px);
    margin: auto;
    padding-top: 170px;
    padding-bottom: 30px;
}

.pageBanner-title{
    color: white;
    font-size: 60px;
    font-weight: bold;
    margin-bottom: 10px;
}

.pageBanner-description{
    color: white;
    font-size: 40px;
}

.pageBanner-arrow-icon{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-image: url(../../assets/icons/icon_seta_ouro.png);
    background-size: 130%;
    background-position: center;
    background-repeat: no-repeat;
    margin: auto;
    position: absolute;
    bottom: -30px;
    left: calc(50% - 30px);
    transform: rotate(90deg);
}


@media only screen and (max-width: 700px) {
    .pageBanner-wrapper{
        min-height: 400px;
        display: flex;
    }

    .pageBanner-text-section{
        width: calc(100% - 30px);
        margin: auto auto 30px auto;
        padding: 0px;
    }

    .pageBanner-title{
        font-size: 35px;
    }
    
    .pageBanner-description{
        font-size: 25px;
    }

    .pageBanner-arrow-icon {
        width: 45px;
        height: 45px;
        bottom: -10px;
        left: calc(100% - 60px);
        transform: rotate(-90deg);
        opacity: 0.85;
    }
}