.button-wrapper{
    width: 300px;
    height: 65px;
    line-height: 65px;
    text-align: center;
    cursor: pointer;
    /* background-color: #d0a240; */
    background: linear-gradient(to left, #d0a240 50%, black 50%) right;
    background-size: 200%;
    transition: .25s ease-out;
    color: white;
    font-weight: bold;
    font-size: 17px;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}

.button-wrapper.darkMode{
    background: linear-gradient(to left, #505050 50%,#d0a240 50%) right;
    background-size: 200%;
}

.button-wrapper.centered{
    margin: auto;
}

.button-wrapper:hover{
    background-position: left;
}


@media only screen and (max-width: 700px) {
    .button-wrapper{
        width: 285px;
        height: 50px;
        line-height: 50px;
        font-size: 14px;
    }
}