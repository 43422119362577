.conferencias-page{
    width: 100%;
    height: 100%;
}

.conferencias-grid{
    display: grid;
    padding-top: 40px;
    grid-template-columns: repeat(auto-fill, 420px);
    margin: auto;
    width: 100%;
    justify-content: center;
    background-color: #f2f3f2;
}

.conferencias-more-section{
    width: 100%;
    height: 160px;
    display: flex;
}

@media only screen and (max-width: 700px) {
    .conferencias-grid{
        padding-top: 10px;
        grid-template-rows: 1fr;
        grid-template-columns: repeat(auto-fill, calc(100% - 30px));
    }
}