.project-page{
    width: 100%;
    height: 100%;
}

.project-content{
    width: 100%;
    height: 100%;
}

.project-content-wrapper {
    width: 100%;
    font-size: 18px;
    line-height: 24px;
    margin: auto;
    padding: 20px 0px 0px 0px;
}

.project-content-wrapper a {
    color: #d0a240;
}

.project-content-wrapper .wp-block-columns{
    width: 90%;
    margin: auto;
    display: grid;
    grid-template-columns: calc(50% - 50px) calc(50% - 50px);
    column-gap: 50px;
    justify-content: center;
    padding: 50px 0px;
}

.project-content-wrapper .wp-block-columns.numbers-icvm {
    width: 90%;
    margin: auto;
    display: grid;
    grid-template-columns: calc(25% - 50px) calc(25% - 50px) calc(25% - 50px) calc(25% - 50px);
    grid-column-gap: 50px;
    column-gap: 50px;
    justify-content: center;
    padding: 0px 0px 30px 0px;
    text-align: center;
}

.project-content-wrapper .wp-block-column{
    margin: 0px;
}

.project-content-wrapper .wp-block-image{
    display: flex;
}

.project-content-wrapper .wp-block-image img {
    margin: auto;
    width: 100%;
    padding-bottom: 0%;
    height: intrinsic;
}

.project-content-wrapper .wp-block-image.three-quarters{
    width: 75%;
}

.project-content-wrapper .wp-block-image.half{
    width: 50%;
}

.project-content-wrapper h2{
    width: 90%;
    height: auto;
    min-height: 60px;
    border-bottom: 1px solid black;
    font-size: 38px;
    line-height: 42px;
    font-weight: bold;
    margin: 40px auto 0px auto;
}

.project-content-wrapper h3 {
    font-size: 24px;
    line-height: 30px;
}

.project-content-wrapper h4 {
    font-size: 22px;
}

.project-content-wrapper .wp-block-columns.gray{
    background-color: #f2f3f2;
    padding: 50px 5%;
}

.project-content-wrapper h2.gray{
    background-color: #f2f3f2;
    padding: 0px 5%;
    margin: 0px auto;
}

.project-content-wrapper .dropdown-question-wrapper {
    padding: 0px 5%;
    /* margin: 50px auto; */
}

.project-content-wrapper p.highlight-quote {
    color: #d0a240;
    font-size: 38px;
    line-height: 48px;
    font-weight: bold;
}

.project-content-wrapper figure {
    margin: auto;
    margin-top: 22px;
}

.project-content-wrapper .wp-block-group {
    width: 90%;
    margin: auto;
}

.project-content-wrapper .wp-block-group.gray{
    background-color: #f2f3f2;
    width: 100%;
    margin: 30px 0px 0px 0px;
    padding: 15px 0px;
}

.project-content-wrapper .wp-block-group.gray h2{
    width: 90%;
    margin: 40px auto 0px auto;
}

.project-content-wrapper .wp-block-group.gray p{
    width: 100%;
    margin: auto;
    margin-block-start: 1em;
    margin-block-end: 1em;
}

.project-content-wrapper /* .wp-block-group.gray */ ul{
    width: calc(100% - 20px);
    margin: auto;
    margin-block-start: 1em;
    margin-block-end: 1em;
    padding-inline-start: 20px;
}

.project-content-wrapper li{
    margin-block-start: 5px;
    margin-block-end: 5px;
}

.project-content-wrapper .wp-block-group.center{
    width: 50%;
    margin: 40px auto;
}

.project-content-wrapper .wp-block-group.right{
    width: 60%;
    margin: 40px auto;
    margin-right: 5%;
}

.project-content-wrapper .wp-block-group.content {
    width: 100%;
} 

.project-content-wrapper .item {
    border-top: 1px solid black;
    margin: 50px auto 70px auto;
}

.project-content-wrapper .item h3{
    margin: 20px auto;
}

.project-content-wrapper .item p{
    margin: 20px auto;
}

.project-content-wrapper h3.yellow{
    width: 90%;
    color: #d2ae63;
    margin: 65px auto 0px auto;
}

.project-content-wrapper h4.yellow{
    width: 90%;
    color: #d2ae63;
    margin: 30px auto 0px auto;
}

.project-content-wrapper .wp-block-columns.triple{
    grid-template-columns: repeat(auto-fill, 250px);
    max-width: 1200px;
    grid-column-gap: 150px;
}

@media only screen and (max-width: 700px) {
    .project-content-wrapper .wp-block-columns {
        width: calc(100% - 30px);
        display: block;
        padding: 20px 0px;
    }

    .project-content-wrapper .wp-block-columns.gray {
        padding: 20px 15px;
    }

    .project-content-wrapper .wp-block-group.right {
        width: calc(100vw - 30px);
        margin: 40px auto;
    }

    .project-content-wrapper .wp-block-group.gray p {
        width: 100%;
    }

    .project-content-wrapper h2 {
        min-height: auto;
        padding-bottom: 10px;
        width: calc(100vw - 30px);
    }

    .project-content-wrapper .item {
        border-top: 1px solid black;
        margin: 40px auto;
        width: calc(100vw - 30px);
    }

    .project-content-wrapper .wp-block-columns.triple{
        display: grid
    }

    .project-content-wrapper .wp-block-columns.numbers-icvm {
        grid-template-columns: 1fr 1fr;
    }

    .project-content-wrapper .wp-block-group.center {
        margin: 0px auto;
        width: calc(100vw - 30px);
    }
}