.navbar {
    width: 100%;
    height: 165px;
    display: flex;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    background-color: white;
    position: fixed;
    z-index: 11;
    transition: 0.4s;
}

.navbar.smaller {
    height: 90px;
    transition: 0.4s;
}

.navbar.darkMode {
    background-color: #d0a240;
}

.navbar-wrapper {
    width: 90%;
    margin: auto;
    display: flex;
}

.navbar-logo-container {
    display: flex;
}

.navbar-logo {
    height: 150px;
    width: 270px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(../../assets/images/logo-icvm.svg);
    /* transition: background-image 0.3s ease-in-out; */
    transition: height 0.3s ease-in-out, width 0.3s ease-in-out;
}

.navbar-logo.smaller {
    height: 60px;
    width: 200px;
    transition: height 0.3s ease-in-out, width 0.3s ease-in-out;
}

.navbar-logo.darkMode {
    background-image: url(../../assets/images/logo-icvm-branco.svg);
}

.navbar-logo.darkMode {
    background-color: #d0a240;
}

.navbar-logo:hover {
    background-image: url(../../assets/images/logo-icvm-laranja.svg);
}

.navbar-logo.darkMode:hover {
    background-image: url(../../assets/images/logo-icvm-cinzento.svg);
}

.navbar-pages-section {
    position: relative;
    display: flex;
    margin: auto;
    margin-right: 0px;
}

.navbar-pages-section a {
    text-decoration: none;
}

.navbar-pages-wrapper {
    display: flex;
}

.navbar-page-item {
    color: black;
    margin: auto;
    height: 40px;
    margin-right: 40px;
    display: flex;
    cursor: pointer;
    border-bottom: 0px;
}

.navbar-page-item-title {
    line-height: 40px;
    cursor: pointer;
    font-size: 18px;
}

.navbar-page-item-title::after {
    display: block;
    content: '';
    border-bottom: 1px solid #d0a240;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
    transform-origin: 0% 50%;
}

.navbar-page-item-title:hover::after {
    transform: scaleX(1);
}

/* h1:after{  transform-origin:  0% 50%; } */

/* .navbar-page-item-title:after{
    border-bottom: 1px solid #d0a240;
    transform: scaleX(1);  
    transition: transform 250ms ease-in-out;
    transform-origin:  0% 50%;
}
 */
/* .navbar-page-item-title:hover{
    border-bottom: 1px solid #d0a240;
} */

.navbar-page-item-title.darkMode {}

.navbar-page-item-title.darkMode::after {
    border-bottom: 1px solid white;
}

.navbar-page-item-title.active {
    border-bottom: 1px solid #d0a240;
}

.navbar-page-item-title.active:hover::after {
    transform: scaleX(0);
}

.navbar-pages-search {
    width: 47px;
    height: 47px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(../../assets/icons/icon_lupa_ouro.png);
    margin-top: -5px;
    cursor: pointer;
    transition: ease;
    margin-right: 30px;
    margin-left: -14px;
}

.reserved-area .navbar-page-item {
    color: #d0a240;
    display: flex;
    align-items: center;
}

a.reserved-area .navbar-page-item-title:after {
    content: "";
    top: 11px;
    position: relative;
}

.reserved-area .navbar-page-item-title {
    line-height: 20px;
}

.navbar-pages-search.darkMode {
    background-image: url(../../assets/icons/icon_lupa_branco.png);
}

.nav-bar-search-wrapper {
    width: 100%;
    display: flex;
}

.nav-bar-search-input-wrapper {
    width: 75%;
    margin: auto;
    margin-right: 0px;
    display: flex;
    border-bottom: 1px solid #d0a240;
}

.nav-bar-search-input-wrapper.darkMode {
    border-bottom: 1px solid white;
}

.nav-bar-search-input {
    width: 100%;
    border: none;
    line-height: 40px;
    font-size: 18px;
    background-color: transparent;
}

.nav-bar-search-input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #c2c2c2;
    opacity: 1;
    /* Firefox */
}

.nav-bar-search-input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #c2c2c2;
}

.nav-bar-search-input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #c2c2c2;
}

.nav-bar-search-close {
    width: 47px;
    height: 47px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 64%;
    background-image: url(../../assets/icons/close_icon.svg);
    margin-top: -5px;
    cursor: pointer;
    transition: ease;
}

.nav-bar-menu-mobile {
    display: none;
}

.nav-bar-menu-pages-mobile {
    display: none;
}

@media only screen and (max-width: 1000px) {
    .navbar {
        height: 100px;
    }

    .navbar.smaller {
        height: 100px;
    }

    .navbar-wrapper {
        width: 100%;
    }

    .navbar-logo {
        height: 90px;
        width: 200px;
    }

    .navbar-pages-section {
        display: none;
    }

    .nav-bar-search-input-wrapper {
        width: calc(100% - 15px);
        margin: auto;
        margin-left: 0px;
    }

    .navbar-pages-search {
        width: 42px;
        height: 42px;
        margin-top: 0px;
        margin-left: 0;
    }

    .nav-bar-search-input {
        font-size: 15px;
    }

    .nav-bar-menu-mobile {
        display: grid;
        grid-template-columns: 40px 40px;
        width: 80px;
        height: 40px;
        margin: auto;
        margin-right: 20px;
    }

    .nav-bar-search-button {
        width: 40px;
        height: 40px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url(../../assets/icons/icon_lupa_ouro.png);
    }

    .nav-bar-search-button.darkMode {
        background-image: url(../../assets/icons/icon_lupa_branco.png);
    }

    .nav-bar-menu-button {
        width: 40px;
        height: 40px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url(../../assets/icons/hamburguer_icon.svg);
    }

    .nav-bar-menu-pages-mobile {
        display: block;
        width: 100vw;
        height: calc(100vh - 130px);
        background-color: #f0f3f2;
        position: absolute;
        top: 100px;
        left: 0px;
        z-index: 2;
        padding: 30px 0px 0px 0px;
    }

    .nav-bar-menu-pages-mobile a {
        text-decoration: none;
    }

    .navbar-page-item {
        width: calc(100vw - 40px);
        margin: 8px auto;
        height: 35px;
        line-height: 40px;
    }
}