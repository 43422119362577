.slideshow-wrapper {
    width: 100%;
    height: 600px;
    overflow: hidden;
    position: relative;
}

.slideshow-item-wrapper {
    width: 100%;
    height: 600px;
    background-color: #f2f3f2;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
}

.slideshow-text-section {
    width: calc(100% - 260px);
    margin: auto auto 100px auto;
}

.slideshow-title {
    color: white;
    font-size: 60px;
    font-weight: bold;
}

.slideshow-description {
    color: white;
    font-size: 60px;
    margin-bottom: 10px;
}

.slideshow-link {
    color: #d0a240;
    font-size: 25px;
}

.slideshow-arrow {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-image: url(../../assets/icons/icon_seta_ouro.png);
    background-size: 130%;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 2;
}

.slideshow-arrow.left {
    left: 30px;
    transform: translateY(-50%) rotate(180deg);
}

.slideshow-arrow.right {
    right: 30px;
    transform: translateY(-50%);
}

@media only screen and (max-width: 700px) {
    .slideshow-wrapper {
        height: 400px;
    }

    .slideshow-item-wrapper {
        height: 400px;
    }

    .slideshow-arrow {
        display: none; 
    }

    .slideshow-text-section {
        width: calc(100% - 30px);
        margin: auto auto 30px auto;
    }

    .slideshow-title {
        font-size: 30px;
    }
    
    .slideshow-description {
        font-size: 30px;
        margin-bottom: 5px;
    }

    .slideshow-link {
        font-size: 18px;
    }
}
