.atlas-subpage-content{
    width: 100%;
    height: 100%;
}

.atribuidos-grid{
    display: grid;
    padding-top: 40px;
    grid-template-columns: repeat(auto-fill, 420px);
    margin: auto;
    width: 100%;
    justify-content: center;
    max-width: 1300px;
}

.atribuidos-more-section{
    width: 100%;
    height: 160px;
    display: flex;
    background-color: #505050;
}

.niveis-acessibilidade-wrapper{
    width: 90%;
    margin: 40px auto 0px auto;
}

.niveis-acessibilidade-description {
    width: 58%;
    margin: 40px 5% 0px 5%;
}

@media only screen and (max-width: 1000px) {
    .niveis-acessibilidade-description {
        width: 90%;
        margin: 40px auto 0px auto;
    }
}