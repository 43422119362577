.highlight-item{
    width: 100%;
    height: auto;
    margin: 25px auto;
    background-color: white;
}

.highlight-item a{
    text-decoration: none;
    color: black;
}

.highlight-item-content-section{
    width: 100%;
    height: auto;
}

.highlight-item-content{
    width: calc(100% - 40px);
    margin: auto;
    padding: 20px;
}

.highlight-item-image{
    width: 100%;
    height: 175px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
}

.highlight-item-title{
    max-height: 105px;
    font-weight: bold;
    font-size: 18px;
    padding-bottom: 12px;
    border-bottom: 1px solid black;
}

.highlight-item-title:hover{
    animation: hoverOrange 0.3s;
    color: #d0a240;
}

.highlight-item-date{
    height: 45px;
    line-height: 45px;
    font-size: 18px;
}

.highlight-item-button{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-image: url(../../assets/icons/icon_seta_ouro.png);
    background-size: 130%;
    background-position: center;
    background-repeat: no-repeat;
    margin-left: auto;
    margin-right: 0;
}