.conferencia-item{
    width: calc(100% - 30px);
    height: auto;
    margin: 35px auto;
    background-color: white;
}

.conferencia-item.darkMode{
    background-color: #f1f3f2;
}

.conferencia-item-content{
    width: calc(100% - 40px);
    margin: 5px auto 20px auto;
}

.conferencia-item-date-section{
    border-bottom: 1px solid black;
    display: flex;
    padding-bottom: 15px;
    color: #4f4f4f;
    height: 80px;
}

.conferencia-item-day{
    font-size: 80px;
    font-weight: bold;
    width: 100%;
}

.conferencia-item-month-year{
    font-size: 18px;
    text-align: right;
    width: 100%;
    align-self: flex-end;
    margin-right: 15px;
}

.conferencia-item-title{
    height: 100px;
    margin-top: 15px;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
}

.conferencia-item-image{
    width: 100%;
    height: 300px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    border-top: 10px solid #d0a340;
}

/* .conferencia-item-image-overlay{
    width: 100%;
    height: 100%;
    transition: 0.3s;
} */

.conferencia-item-image:hover > .conferencia-item-image-overlay{
    height: 100%;
    width: 100%;
    background-color:rgba(255,255,255,0.5);
    transition: 0.3s;
}

/* .conferencia-item-image:hover > .conferencia-item-image-overlay .conferencia-item-button-wrapper{
    width: 100%;
    height: 100%;
    display: flex;
} */

@media only screen and (max-width: 700px) {
    .conferencia-item{
        width: 100%;
    }

    .conferencia-item-title {
        min-height: 90px;
    }

    .conferencia-item-image {
        height: 240px;
    }

    .conferencia-item {
        margin: 10px auto;
    }
}