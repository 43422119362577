.biblioteca-page{
    width: 100%;
    height: 100%;
}

.biblioteca-grid-section{
    background-color: #f2f3f2;
    width: 100%;
    padding: 80px 0px;
}

.biblioteca-title{
    margin: auto;
    width: 90%;
    height: 60px;
    border-bottom: 1px solid black;
    font-size: 32px;
    font-weight: bold;
}

.biblioteca-grid{
    display: grid;
    grid-template-columns: repeat(auto-fill, 335px);
    margin: auto;
    width: 100%;
    justify-content: center;
    margin-top: 20px;
}

.biblioteca-more-section{
    width: 100%;
    height: 160px;
    display: flex;
}

.feature-book-wrapper{
    display: grid;
    grid-template-columns: repeat(auto-fill, 650px);
    max-width: 1300px;
    justify-content: center;
    margin: 100px auto;
}

.feature-book-info-wrapper{
    height: 600px;
    width: 90%;
    margin: auto;
    position: relative;
}

.feature-book-cover-wrapper{
    height: 600px;
    width: 90%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin: auto;
}

.feature-book-info-title{
    width: 100%;
    height: auto;
    padding-bottom: 10px;
    border-bottom: 1px solid #000;
    font-size: 32px;
    font-weight: 700;
    margin: auto auto 20px;
}

.feature-book-info-description{
    font-size: 18px;
    line-height: 24px;
    width: 100%;
    margin: auto;
}

.feature-book-info-section{
    position: absolute;
    bottom: 20px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0px 20px;
}

.book-info-item-wrapper{
    border-top: 1px solid #d0a240;;
    width: 100%;
    margin: auto;
    font-size: 16px;
    margin-bottom: 25px;
}

.book-info-item-title{
    color: #d0a240;
    height: 25px;
    line-height: 25px;
}

.book-info-item-value{
    font-weight: bold;
    height: 20px;
    line-height: 20px;
}

@media only screen and (max-width: 700px) {
    .feature-book-wrapper {
        grid-template-columns: repeat(auto-fill, calc(100vw - 30px));
        margin: 30px auto;
    }

    .feature-book-info-wrapper {
        height: auto;
        width: 100%;
    }

    .feature-book-info-section {
        position: inherit;
        bottom: unset;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        margin: 30px auto;
    }

    .book-info-item-wrapper {
        width: 100%;
        font-size: 15px;
    }

    .feature-book-cover-wrapper {
        height: auto;
        padding-bottom: 100%;
        width: 100%;
        order: -1;
        margin: 25px auto;
    }
}