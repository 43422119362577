.map-wrapper{
    width: 90%;
    height: 450px;
    margin: 20px auto;
}

.marker{
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  position: relative;
}

.marker-info {
  width: 120px;
  margin-left: -50px;
  background-color: white;
  color: black;
  padding: 10px;
  text-align: center;
  position: absolute;
  margin-top: 40px;
  border-radius: 3px;
  z-index: 10;
}

.marker-title{
  font-weight: bold;
}