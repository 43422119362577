.dropdown-question-wrapper{
    width: 90%;
    margin: 50px auto;
}

.question-item-wrapper{
    margin-bottom: 20px;
}

.question-item-question-section{
    display: flex;
}

.question-item-plus{
    width: 95px;
    height: 95px;
    cursor: pointer;
    background-color: #d0a240;
    background-image: url(../../assets/icons/icon_mais_branco_semfundo.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 120%;
    transition: 0.3s;
}

.question-item-plus:hover{
    background-size: 145%;
}

.question-item-plus.open{
    background-image: url(../../assets/icons/icon_menos_branco_semfundo.png);
}

.question-item-question{
    height: 95px;
    line-height: 95px;
    width: 100%;
    flex: 1;
    background-color: white;
    font-weight: 600;
    font-size: 21px;
    padding-left: 30px;
}

.question-item-question.gray{
    background-color: #f2f3f3;
}

.question-item-answer{
    width: calc(100% - 125px);
    font-size: 18px;
    line-height: 24px;
    margin: 0px 0px 0px auto;
    padding-left: 30px;
    border-bottom: 0px solid black;
    padding-bottom: 0px;
    overflow: hidden;
    max-height: 0px;
    transition: all 0.5s ease-in-out, border-bottom 0.1ms;
    height: auto;
    visibility: hidden;
}

.question-item-answer.isOpen{
    max-height: 1000px;
    border-bottom: 1px solid black;
    margin: 40px 0px 50px auto;
    padding-bottom: 40px;
    visibility: visible;
}

@media only screen and (max-width: 1000px) {
    .dropdown-question-wrapper{
        width: 90%;
        margin: 30px auto 50px auto;
    }

    .question-item-wrapper {
        margin-bottom: 45px;
    }
    
    .question-item-question-section {
        display: block;
        position: relative;
    }

    .question-item-plus {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        position: absolute;
        left: calc(50% - 25px);
        bottom: -25px;
    }

    .question-item-question {
        height: auto;
        line-height: 25px;
        width: calc(100vw - 65px);
        font-size: 18px;
        padding: 15px;
        padding-bottom: 35px;
    }

    .question-item-answer {
        width: 100%;
        padding: 0px;
    } 

    .question-item-answer.isOpen{
        width: 100%;
        max-height: 1000px;
        padding: 0px;
        border-bottom: 0px solid black;
        margin: 15px 0px 30px auto;
        visibility: visible;
    }
}