.footer{
    width: 100%;
    height: 550px;
    background-color: #4f4f4f;
    display: grid;
    grid-template-columns: 500px 1fr 1fr;
}

.footer-section{
    display: flex;
}

.footer-section.lightMode{
    background-color: #f2f3f2;
}

.footer-section-wrapper{
    height: 410px;
    margin: auto;
}

.footer-section-title{
    color: #d0a240;
    font-size: 43px;
}

.footer-section-content{
    margin-top: 45px;
}

.footer-section-content a{
    text-decoration: none;
}

.footer-contact-section{
    color: white;
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 24px;
}

.footer-page-item{
    margin-bottom: 18px;
    width: fit-content;
}

.footer-page-item-title{
    color: black;
    font-size: 19px;
    line-height: 30px;
}

.footer-page-item-title.active{
    border-bottom: 1px solid #d0a240;
}

.footer-page-item-title::after {
    display:block;
    content: '';
    border-bottom: 1px solid #d0a240; 
    transform: scaleX(0);  
    transition: transform 250ms ease-in-out;
    transform-origin:  0% 50%;
}

.footer-page-item-title:hover::after { transform: scaleX(1); }

.footer-page-item-title.active:hover::after { transform: scaleX(0); }

.footer-social-wrapper{
    display: flex;
}

.footer-social-item{
    width: 45px;
    height: 45px;
    margin-right: 45px;
    background-position: center;
    background-size: cover;
    transition: 0.3s;
}

.linkedin{
    background-image: url(../../assets/icons/icon_linkedin.png);
}

.facebook{
    background-image: url(../../assets/icons/icon_fb.png);
}

.vimeo{
    background-image: url(../../assets/icons/icon_vimeo.png);
}

.linkedin:hover{
    background-image: url(../../assets/icons/icon_linkedin_laranja.png);
}

.facebook:hover{
    background-image: url(../../assets/icons/icon_fb_laranja.png);
}

.vimeo:hover{
    background-image: url(../../assets/icons/icon_vimeo_laranja.png);
}

.footer-contact-newsletter-wrapper{
    display: flex;
    height: 35px;
    border-bottom: 1px solid white;
    font-size: 17px;
}

.footer-contact-newsletter-input{
    width: 100%;
    border: none;
    line-height: 35px;
    background: none;
    color: white;
    font-size: 17px;
}

.footer-contact-newsletter-button {
    cursor: pointer;
}

@media only screen and (max-width: 1000px) {
    .footer {
        grid-template-columns: 1fr;
        width: 100vw;
        height: auto;
        display: block;
    }

    .footer-section-wrapper {
        height: auto;
        display: grid;
        grid-template-columns: 50% 50%;
        margin: 30px auto;
        width: calc(100% - 30px);
    }

    .footer-section-title {
        font-size: 26px;
        line-height: 18px;
    }

    .footer-section-content {
        margin-top: 0px;
    }

    .footer-page-item-title {
        font-size: 13px;
        line-height: 15px;
    }

    .footer-contact-section {
        font-size: 13px;
        line-height: 15px;
        word-wrap: break-word;
        max-width: 230px;
    }

    .footer-social-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }

    .footer-social-item {
        width: 30px;
        height: 30px;
        margin-right: 0px;
    }

    .footer-contact-newsletter-wrapper{
        height: 25px;
        font-size: 13px;
    }
    
    .footer-contact-newsletter-input{
        line-height: 25px;
        font-size: 13px;
    }
}