.book-item{
    width: calc(100% - 50px);
    height: auto;
    margin: 25px auto 70px auto;
}

.book-item-content-section{
    width: 100%;
    height: auto;
}

.book-item-content{
    width: 100%;
    margin: auto;
    margin-top: 35px;
    border-top: 1px solid black;
}

.book-item-image{
    width: 100%;
    height: 285px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: 0.3s;
}

.book-item-image:hover > .book-item-image-overlay{
    height: 100%;
    width: 100%;
    background-color:rgba(255,255,255,0.5);
    transition: 0.3s;
}

.book-item-title{
    font-weight: bold;
    font-size: 19px;
    margin-top: 12px;
}

.book-item-description{
    line-height: 18px;
    font-size: 18px;
    margin-top: 18px;
    color: #656665;
}