.atualidade-item{
    width: calc(100% - 30px);
    height: auto;
    margin: 35px auto;
    background-color: white;
}

.atualidade-item.firstItem{
    grid-column-start: 1; 
    grid-column-end: 3; 
}

.atualidade-item-content{
    width: calc(100% - 40px);
    margin: auto;
    border-top: 1px solid black;
    margin-top: 40px;
    margin-bottom: 20px;
}

.atualidade-item-content a{
    text-decoration: none;
    color: none;
}

.atualidade-item-image{
    width: 100%;
    height: 300px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
}

.atualidade-item-title{
    height: 150px;
    margin-top: 15px;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    cursor: pointer;
    color: black;
}

.atualidade-item-title:hover{
    animation: hoverOrange 0.3s;
    color: #d0a240;
}

.atualidade-item-title.firstItem{
    font-size: 35px;
    line-height: 40px;
}

.atualidade-item-date{
    font-size: 18px;
    line-height: 24px;
}

.atualidade-item-button{
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background-image: url(../../assets/icons/icon_seta_ouro.png);
    background-size: 130%;
    background-position: center;
    background-repeat: no-repeat;
    margin-left: auto;
    margin-right: 0;
    background-color: #d0a340;
    transition: 0.2s;
}

.atualidade-item-button:hover{
    background-position: -3px center;
}

@media only screen and (max-width: 700px) {
    .atualidade-item {
        width: 100%;
        margin: 10px auto;
    }

    .atualidade-item-image {
        height: 240px;
    }
    
    .atualidade-item.firstItem{
        grid-column-start: 1; 
        grid-column-end: 1; 
    }

    .atualidade-item-title.firstItem{
        font-size: 20px;
    }

    .atualidade-item-title {
        height: auto;
        min-height: 90px;
        padding-bottom: 20px;
        font-size: 20px;
    }
}