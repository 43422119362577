.projeto-item{
    width: calc(100% - 40px);
    height: auto;
    margin: 45px auto;
    background-color: white;
}

.projeto-item-title{
    height: 53px;
    font-weight: bold;
    font-size: 31px;
    border-bottom: 1px solid black;
    margin-bottom: 20px;
}

.projeto-item-description{
    height: 46px;
    font-size: 18px;
    margin-bottom: 35px;
}

.projeto-item-image{
    width: 100%;
    height: 430px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    display: flex;
}

.projeto-item-image:hover > .projeto-item-button{
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-image: url(../../assets/icons/icon_mais_ouro.png);
    background-size: 150%;
    background-position: center;
    background-repeat: no-repeat;
    margin: auto;
    animation: fadeInLoad 0.5s;
}

@keyframes fadeInLoad {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}

@media only screen and (max-width: 700px) {
    .projeto-item {
        width: 100%;
        margin: 35px auto;
    }

    .projeto-item-title {
        height: auto;
        font-weight: bold;
        font-size: 30px;
        padding-bottom: 15px;
        margin-bottom: 15px;
    }

    .projeto-item-description {
        min-height: 45px;
        height: auto;
        padding-bottom: 15px;
        margin-bottom: 0px;
    }

    .projeto-item-image {
        height: 240px;
    }
}