.projeto-page{
    width: 100%;
    height: 100%;
}

.projeto-grid{
    display: grid;
    padding: 40px 0px;
    grid-template-columns: repeat(auto-fill, 650px);
    margin: auto;
    width: 100%;
    justify-content: center;
}

@media only screen and (max-width: 700px) {
    .projeto-grid {
        grid-template-columns: repeat(auto-fill, calc(100vw - 30px));
        padding: 30px 0px;
    }
}