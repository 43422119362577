.contactos-page{
    width: 100%;
    height: 100%;
}

.contactos-section{
    width: 100%;
}

.contactos-title{
    margin: auto;
    width: 90%;
    height: 60px;
    border-bottom: 1px solid black;
    font-size: 32px;
    font-weight: bold;
    margin-top: 50px;
}

/* .contactos-map{
    width: 90%;
    height: 600px;
    background-image: url("../../assets/images/contact_map_fake.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 20px auto;
} */

.contactos-map{
    width: 90%;
    /* height: 600px; */
    margin: 20px auto;
}

.contactos-feedback-section{
    background-color: #f2f3f2;
    width: 100%;
    height: auto;
    margin: 50px 0px 0px 0px;
}

.contactos-feedback-wrapper{
    display: grid;
    grid-template-columns: repeat(auto-fill, 650px);
    justify-content: center;
    margin: auto;
    padding: 50px 0px;
    max-width: 1300px;
}

.contactos-feedback-left-wrapper{
    height: 600px;
}

.contactos-feedback-right-wrapper{
    height: 600px;
}

.contact-form-wrapper{
    width: calc(100% - 60px);
    margin: auto;
}

.contact-input{
    width: calc(100% - 25px);
    border: none;
    line-height: 55px;
    font-size: 18px;
    background-color: white;
    margin: 10px 10px 0px 10px;
    padding: 0px 0px 0px 10px;
}

/* .newsletter{
    margin: 10px 0px;
} */

.contact-split-input-row{
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.contact-button-send{
    width: calc(100% - 20px);
    height: 50px;
    line-height: 50px;
    color: white;
    text-align: center;
    cursor: pointer;
    background-color: #4f4f4f;
    margin: auto;
    margin-top: 35px;
}

.contactos-feedback-left-wrapper{
    font-size: 20px;
    width: calc(100% - 130px);
    margin: auto;
    font-size: 18px;
    line-height: 24px;
}

.contactos-feedback-title{
    font-size: 35px;
    line-height: 40px;
    font-weight: bold;
    margin-bottom: 20px;
}

.contactos-feedback-regulation{
    color: #d0a240;
    font-size: 21px;
    text-decoration: underline;
    cursor: pointer;
    margin-bottom: 30px;
    margin-top: 15px;
}

.contactos-feedback-subtitle{
    font-size: 20px;
    line-height: 26px;
    font-weight: bold;
    margin-top: 15px;
}

@media only screen and (max-width: 700px) {
    .contactos-title {
        margin-top: 30px;
        width: calc(100vw - 30px);
        height: auto;
        min-height: auto;
        padding-bottom: 10px;
    }

    .contactos-map {
        width: 90%;
        /* height: 160px; */
    }

    .contactos-section {
        padding: 0px;
    }

    .contactos-feedback-wrapper {
        grid-template-columns: repeat(auto-fill, calc(100vw - 30px));
        padding: 30px 0px;
    }

    .contact-form-wrapper {
        width: 100%;
    }

    .contact-input {
        width: calc(100% - 10px);
        margin: 5px 0px;
        padding: 0px 0px 0px 10px;
    }

    .contact-split-input-row {
        grid-template-columns: 1fr;
    }

    .contactos-feedback-left-wrapper {
        font-size: 18px;
        margin-bottom: 40px;
        height: auto;
        width: 100%;
    }

    .contactos-feedback-regulation {
        font-size: 20px;
    }
}