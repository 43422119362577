.gallery-wrapper {
    width: 90%;
    margin: 50px auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, 420px);
    justify-content: center;
}

.gallery-image-item{
    height: 280px;
    width: 400px;
    margin: 10px auto;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    display: flex;
}

.gallery-image-item:hover > .gallery-image-item-button{
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-image: url(../../assets/icons/icon_mais_ouro.png);
    background-size: 150%;
    background-position: center;
    background-repeat: no-repeat;
    margin: auto;
    animation: fadeInLoad 0.5s;
}

@keyframes fadeInLoad {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}

.open-gallery-wrapper{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
}

.open-gallery-image-wrapper{
    width: 100%;
    display: grid;
    grid-template-rows: 50px calc(100vh - 50px);
}

.open-gallery-image-close-wrapper{
    width: 100%;
    display: flex;
}

.open-gallery-image-close{
    margin: auto;
    color: white;
    cursor: pointer;
    width: min-content;
    font-size: 17px;
}

.open-gallery-image-arrows{
    display: flex;
    grid-template-columns: 10% 80% 10%;
    width: 100%;
    height: 100%;
}

.open-gallery-image{
    width: 70%;
    height: 80%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin: auto;
}

.open-gallery-arrow{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-image: url(../../assets/icons/icon_seta_ouro.png);
    background-size: 130%;
    background-position: center;
    background-repeat: no-repeat;
    margin: auto;
    position: absolute;
    cursor: pointer;
    z-index: 2;
    top: calc(50vh - 30px);
}

.open-gallery-arrow.left{
    left: calc(50% - 30px);
    transform: rotate(180deg);
    left: 30px;
    margin-left: 0px;
}

.open-gallery-arrow.right{
    left: calc(50% - 30px);
    transform: rotate(0deg);
    right: 30px;
    margin-right: 0px;
}

@media only screen and (max-width: 700px) {
    .gallery-wrapper {
        width: calc(100vw - 30px);
        margin: 30px auto;
        grid-template-columns: 1fr;
        justify-content: center;
    }

    .gallery-image-item {
        height: 240px;
        width: 100%;
        margin: 10px auto;
    }
}