.nivel-acessibilidade-wrapper{
    display: grid;
    grid-template-columns: calc(100% - 450px) 100px 350px;
    margin: 20px 0px;
}

.nivel-acessibilidade-text-section{
    background-color: #f0f3f2;
    padding: 40px;
    display: flex;
}

.nivel-acessibilidade-text-wrapper{
    margin: auto 0px;
}

.nivel-acessibilidade-title{
    font-weight: bold;
    margin-bottom: 20px;
}

.nivel-acessibilidade-description{
    
}

.nivel-acessibilidade-triangle{
    border-top: 140px solid transparent;
    border-bottom: 140px solid transparent;
    border-left: 40px solid #f0f3f2;
}

.nivel-acessibilidade-image-section{
    display: flex;
    line-height: 33px;
}

.nivel-acessibilidade-image-wrapper{
    margin: auto;
    margin-left: 0px;
    width: calc(100% - 120px);
    height: calc(100% - 60px);
}

.nivel-acessibilidade-image{
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 170px;
    height: 170px;
    margin: auto;
}


.nivel-acessibilidade-nivel-name{
    text-align: center;
    font-size: 30px;
    font-weight: bold;
}

.nivel-acessibilidade-nivel-number{
    text-align: center;
    font-weight: 600;
}

.nivel-acessibilidade-nivel-number.nivel1{
    color: #40a5d5;
}

.nivel-acessibilidade-nivel-number.nivel2{
    color: #9b9b9b;
}

.nivel-acessibilidade-nivel-number.nivel3{
    color: #d0a240;
}


@media only screen and (max-width: 1000px) {
    .nivel-acessibilidade-wrapper {
        grid-template-columns: 1fr;
        margin: 20px 0px 50px;
    }

    .nivel-acessibilidade-wrapper:first-child{
        grid-column: 3;
    }

    .nivel-acessibilidade-triangle {
        border: 0px;
    }

    .nivel-acessibilidade-image-wrapper {
        margin: 10px auto;
    }
}