.search-item{
    width: 100%;
    height: auto;
    padding-bottom: 15px;
    margin: 20px auto;
}

.search-item.hasBorder{
    border-bottom: 1px solid black;
}

.search-item-image{
    width: 100%;
    height: 430px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    display: flex;
    margin-bottom: 40px;
}

.search-item-title{
    font-weight: bold;
    font-size: 31px;
    margin-bottom: 15px;
}

.search-item-description{
    min-height: 18px;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 15px;
}

.search-item-image:hover > .search-item-button{
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-image: url(../../assets/icons/icon_mais_ouro.png);
    background-size: 150%;
    background-position: center;
    background-repeat: no-repeat;
    margin: auto;
    animation: fadeInLoad 0.5s;
}

.search-item-link{
    color: #d0a240;
    font-size: 18px;
    line-height: 24px;
}

.search-item-link:hover {
    animation: hoverBlack 0.4s;
    color: black;
}

@media only screen and (max-width: 700px) {
    .search-item-image {
        height: 240px;
    }

    .search-item-title {
        font-size: 26px;
    }

    .search-item-description {
        font-size: 17px;
        margin-bottom: 10px;
    }

    .search-item-link {
        font-size: 18px;
    }
}