.previewSection-wrapper{
    width: 100%;
    height: auto;
    min-height: 800px;
    background-color: white;
    padding-top: 70px;
    padding-bottom: 60px;
}

.previewSection-wrapper.darkMode{
    background-color: #4f4f4f;
}

.previewSection-header{
    width: 90%;
    display: flex;
    margin: auto;
    height: 95px;
    border-bottom: 1px solid black;
}

.previewSection-header.darkMode{
    border-bottom: 1px solid white;
}

.previewSection-header a{
    text-decoration: none;
}

.previewSection-title{
    font-size: 60px;
    font-weight: bold;
    flex: 1;
}

.previewSection-title.darkMode{
    color: white;
}

.previewSection-grid{
    display: grid;
    padding-top: 40px;
    grid-template-columns: repeat(3, 420px);
    margin: auto;
    width: 100%;
    justify-content: center;
}

.previewSection-mobile{
    display: none;
}

.previewSection-mobile a{
    text-decoration: none;
}

@media only screen and (max-width: 1270px) {
    .previewSection-grid {
        display: grid;
        padding-top: 40px;
        grid-template-columns: repeat(3, 420px);
        margin: auto;
        max-width: calc(100% - 40px);
        width: calc(100% - 40px);
        overflow-x: auto;
        justify-content: unset;
    }
}

@media only screen and (max-width: 700px) {
    .previewSection-wrapper {
        padding: 40px 0px;
        min-height: auto;
    }

    .previewSection-grid {
        display: grid;
        padding-top: 5px;
        grid-template-columns: repeat(3, calc(100vw - 60px));
        margin: auto;
        margin-right: 0px;
        margin-left: 0px;
        width: 100%;
        overflow-x: auto;
        justify-content: unset;
        grid-column-gap: 30px;
        grid-column-gap: 15px;
        column-gap: 15px;
        padding-left: 15px;
        padding-right: 25px;
    }

    .previewSection-header {
        height: 55px;
        width: calc(100vw - 30px);
    }

    .previewSection-title {
        font-size: 35px;
    }

    .previewSection-more-button{
        display: none;
    }

    .previewSection-mobile {
        display: block;
        width: 100%;
        display: flex;
        margin: 30px 0px;
    }

    .previewSection-mobile a {
        text-decoration: none;
        margin: auto;
    }
}