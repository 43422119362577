.pesquisa-page{
    width: 100%;
    height: 100%;
}

.pesquisa-content{
    width: 100%;
    height: 100%;
    margin: auto;
    margin-top: 120px;
}

.pesquisa-title{
    margin: auto;
    width: calc(100% - 200px);
    height: 75px;
    border-bottom: 1px solid black;
    font-size: 45px;
    font-weight: bold;
}

.pesquisa-list{
    padding-top: 40px;
    margin: auto;
    width: calc(100% - 200px);
    justify-content: center;
}

.pesquisa-more-section{
    width: 100%;
    height: 160px;
    display: flex;
    background-color: white;
}

@media only screen and (max-width: 700px) {
    .pesquisa-content {
        margin-top: 40px;
    }

    .pesquisa-title {
        height: auto;
        width: calc(100vw - 30px);
        min-height: 75px;
        padding-bottom: 15px;
    }

    .pesquisa-list {
        padding-top: 20px;
        width: calc(100vw - 30px);
    }
}