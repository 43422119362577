.atribuido-item{
    width: calc(100% - 40px);
    height: auto;
    margin: 35px auto;
}

.atribuido-item.firstItem{
    grid-column-start: 1; 
    grid-column-end: 3; 
}

.atribuido-item-content{
    width: 100%;
    margin: auto;
    border-top: 1px solid black;
    margin-bottom: 20px;
}

.atribuido-item-image{
    width: 100%;
    height: 300px;
    max-height: 300px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 40px;
    transition: max-height 0.3s;
    overflow: hidden;
}

.atribuido-item-image.isOpen{
    max-height: 0px;
    transition: max-height 0.3s;
    margin-bottom: 0px;
}

.atribuido-item-description-section{
    max-height: 0px;
    transition: max-height 0.3s;
    overflow: hidden;
}

.atribuido-item-description-section.isOpen{
    max-height: 500px;
    transition: all 0.3s;
}

.atribuido-item-title{
    min-height: 65px;
    margin-top: 15px;
    font-weight: bold;
    font-size: 26px;
    line-height: 32px;
}

.atribuido-item-description{
    font-size: 16px;
    line-height: 22px;
}

.atribuido-item-button{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-size: 140%;
    background-position: center;
    background-repeat: no-repeat;
    margin-left: 0px;
    margin-right: auto;
    background-color: #d0a240;
    cursor: pointer;
    transition: 0.3s;
}

.atribuido-item-button.plus:hover{
    background-size: 155%;
}

.atribuido-item-button.plus{
    background-image: url(../../assets/icons/icon_mais_branco_semfundo.png);
}

.atribuido-item-button.minus{
    background-image: url(../../assets/icons/icon_menos_branco_semfundo.png);
}

.atribuido-item-link{
    font-size: 16px;
    line-height: 22px;
    display: block;
    color: #d0a240;
}

.atribuido-item-nivel-wrapper{
    height: 296px;
    width: 100%;
    display: grid;
    grid-template-rows: 100px calc(100% - 120px) 20px;
}

.atribuido-item-triangle{
    content: "";
    display: block;
    width: 0;
    height: 0;
    margin: auto;
    margin-bottom: 0px;
    border-left: 190px solid transparent;
    border-right: 190px solid transparent;
    border-bottom: 40px solid #f2f3f2;
}

.atribuido-item-nivel-container{
    height: 100%;
    width: 100%;
    margin: auto;
    margin-bottom: 0px;
    background-color: #f2f3f2;
    display: flex;
}

.atribuido-item-triangle.darkMode{
    border-left: 190px solid transparent;
    border-right: 190px solid transparent;
    border-bottom: 40px solid white;
}

.atribuido-item-nivel-container.darkMode{
    background-color: white;
}

.atribuido-item-nivel{
    margin: auto;
    margin-top: 0px;
}

.atribuido-item-nivel-image{
    width: 140px;
    height: 140px;
    margin: auto;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.atribuido-item-nivel-title{
    font-weight: bold;
    font-size: 15px;
    text-align: center;
}

@media only screen and (max-width: 700px) {
    .atribuidos-grid {
        padding-top: 20px;
        grid-template-columns: repeat(auto-fill, calc(100vw - 30px));
    }

    .atribuido-item {
        width: 100%;
        margin: 15px auto;
    }

    .atribuido-item-image {
        height: 240px;
    }

    .atribuido-item-triangle {
        border: 0px;
    }
}