.appContainer{
    width: 100%;
    height: 100vh;
}

.appContainer-content{
    padding-top: 165px;
}

@media only screen and (max-width: 1000px) {
    .appContainer-content {
        padding-top: 100px;
    }
}