.postBanner-wrapper{
    width: 100%;
    min-height: 400px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    background-color: #f2f3f2;
}

.postBanner-text-section{
    width: calc(100% - 400px);
    margin: auto auto auto 10%;
    padding: 70px 0px;
}

.postBanner-postType{
    font-size: 35px;
    color: #d0a240;
}

.postBanner-title{
    color: black;
    font-size: 60px;
    font-weight: bold;
    margin-bottom: 10px;
}

.postBanner-description{
    color: black;
    font-size: 22px;
}

.postBanner-arrow-icon{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-image: url(../../assets/icons/icon_seta_cinza.png);
    background-size: 130%;
    background-position: center;
    background-repeat: no-repeat;
    margin: auto;
    position: absolute;
    bottom: -30px;
    left: calc(50% - 30px);
    transform: rotate(90deg);
}

@media only screen and (max-width: 700px) {
    .postBanner-wrapper{
        min-height: 400px;
        display: flex;
    }

    .postBanner-text-section{
        width: calc(100% - 30px);
        margin: 30px auto;
        padding: 0px;
    }

    .postBanner-title{
        font-size: 35px;
    }
    
    .postBanner-description{
        font-size: 25px;
    }

    .postBanner-arrow-icon {
        width: 45px;
        height: 45px;
        bottom: -10px;
        left: calc(100% - 60px);
        transform: rotate(-90deg);
        opacity: 0.85;
    }
}