.post-page{
    width: 100%;
    height: 100%;
}

.post-content{
    width: 100%;
    height: 100%;
}

.post-content-image{
    width: 90%;
    height: 550px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    margin: 70px auto 0px auto;
    display: none;
}

.post-content-wrapper{
    width: 65%;
    margin: 70px auto;
    font-size: 18px;
    line-height: 24px;
}

.post-content-wrapper .wp-block-image {
    width: 100%;
}

.post-content-wrapper .wp-block-image img{
    margin: auto;
}

.post-content-wrapper figure{
    margin: auto;
}

.post-content-wrapper .wp-block-image{
    display: flex;
}

.post-content-wrapper .wp-block-image img {
    margin: auto;
    max-width: 60%;
    background-size: contain;
    height: auto;
}

.post-content-wrapper .wp-block-gallery{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    row-gap: 20px;
    list-style-type: none;
    width: 90%;
    margin: auto;
    margin-top: 60px;
}

.post-content-wrapper ul{
    padding: 0px;
}

.post-content-wrapper .wp-block-gallery img{
    width: 100%;
}

.post-content-wrapper a{
    color:#d0a240;
}

@media only screen and (max-width: 700px) {
    .post-content-wrapper {
        width: calc(100% - 30px);
        margin: 20px auto 50px auto;
        font-size: 20px;
        word-wrap: break-word;
    }

    .post-content-image {
        width: calc(100% - 30px);
        height: 250px;
        margin: 20px auto 0px auto;
    }

    .post-content-wrapper .wp-block-image img {
        max-width: 100%;
    }

    .post-content-wrapper iframe{
        width: 100%;
        height: auto;
    }
}